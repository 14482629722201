<template>
  <settings-layout>
    <template #actions>
      <b-button size="sm" variant="link" class="p-0 mr-1" @click="patchSetting">
        <feather-icon icon="UploadCloudIcon" size="16" class="align-middle text-body"/>
      </b-button>
    </template>

    <template #content>
      <template v-if="setting">
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-title">Enabled</div>
              <div class="setting-desc">This setting toggles the ability to manage selections. If disabled, all groups without 'manage' access will lose the ability to edit, update and delete selections. </div>
            </div>
            <div class="setting-state">
              <b-form-checkbox v-model="setting.enabled"
                               switch size="sm"
                               @change="patchSetting"/>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-title">Hotel Fee</div>
              <div class="setting-desc">This setting toggles the ability to manage selections. If disabled, all groups without 'manage' access will lose the ability to edit, update and delete selections. </div>
              <div class="setting-input">
                <b-input v-model="setting.fees.hotel" number></b-input>
              </div>
            </div>
            <div class="setting-state"></div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-title">NYSSMA Fee</div>
              <div class="setting-desc">This setting toggles the ability to manage selections. If disabled, all groups without 'manage' access will lose the ability to edit, update and delete selections. </div>
              <div class="setting-input">
                <b-input v-model="setting.fees.nyssma" number></b-input>
              </div>
            </div>
            <div class="setting-state"></div>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <debug title="setting" collapsed class="mt-2">{{ setting }}</debug>
    </template>
  </settings-layout>
</template>

<script>
import { API, graphqlOperation} from 'aws-amplify';
import { getSetting } from '@/graphql/queries';
import { updateSetting } from '@/graphql/mutations';
import notify from '@/mixins/notify.mixin';
import SettingsLayout from '@/views/management/settings/SettingsLayout.vue';

export default {
  name: 'SettingsBilling',
  components: {SettingsLayout},
  mixins: [ notify ],
  data() {
    return {
      key: 'billing',
      setting: null,
      icon: 'fas fa-gear'
    }
  },
  async mounted() {
    await this.getSetting()
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: this.key }));
      this.setting = JSON.parse(response.data?.getSetting?.value) || null
    },
    async patchSetting() {
      try {
        const settingInput = {
          key: this.key,
          value: JSON.stringify(this.setting)
        }
        const response = await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
        this.setting = JSON.parse(response.data?.updateSetting?.value) || null;
        this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Setting failed to update', icon: this.icon, variant: 'danger'});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/nyssma/settings.scss';
</style>
